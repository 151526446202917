import React from "react"
import {
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FourZeroFourContent from "../components/four-zero-four-content"



const FourZeroFourPage = () => {
  const {t} = useI18next();
  return (
    <Layout>
      <SEO title={t("MENU.CONTACT")} />
      <FourZeroFourContent/>
    </Layout>
  )
}


export default FourZeroFourPage